@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
	height: 100%;
	width: 100%;
	font-family: 'Source Sans Pro', sans-serif;
}

.app {
	display: flex;
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.table-img {
	vertical-align: top;
}

.last-five-results {
	display: flex;
}

.five-results {
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 0.9rem !important;
	width: 20px;
	height: 20px;
	font-weight: 600;
	color: #000;
}

.five-results:first-child {
	border-radius: 5px 0 0 5px;
}

.five-results:last-child {
	border-radius: 0 5px 5px 0;
}

.last-five-results .toolTip {
	background-color: #525252;
	padding: 5px;
	/* margin: 5px; */
	opacity: 1;
}

.relegation {
	/* background-color: red; */
	display: inline-block;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	justify-content: center !important;
	text-align: center;
	align-items: center !important;
}

.fix-skeleton-height {
	display: block;
	line-height: 1;
	font-size: 25px;
}

/* .MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected) {
	border: 1px solid rgba(43, 255, 0, 0.603);
} */

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
	border: 1px solid gold;
}

.MuiButtonBase-root.MuiPickersDay-root {
	font-size: 1rem !important;
	/* color: red; */
	border-color: rgb(255, 81, 0);
}
